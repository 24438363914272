import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useQueryParams } from 'hooks/useQueryParams';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { Heading } from '../Typography';
import { Props } from './Tab.types';
import {
  Container,
  Content,
  GoBackArrow,
  GoForwardArrow,
  Label,
  MobileGoBack,
  Nav,
  NavItem
} from './Tabs.styled';

export const Tabs = ({ tabs, tabUrlKey, onChangeTab, noMargin }: Props) => {
  const { isDesktop } = useBreakpoints();
  const history = useHistory();
  const queryParams = useQueryParams();
  const activeTabId = queryParams.get(tabUrlKey);
  const activeTab = tabs.find(tab => tab.id === activeTabId);

  const changeTab = useCallback(
    (tabId?: string) => {
      // clone existing search params
      const newUrlSearchParams = new URLSearchParams(queryParams);

      if (tabId && tabId !== activeTabId) {
        newUrlSearchParams.set(tabUrlKey, tabId);
        // add tab param to url but respect the existing query params
        history.push(`?${newUrlSearchParams}`);
        onChangeTab?.(tabId);
      } else if (!tabId) {
        // this covers going back to the initial screen on mobile(no tab selected; tab navigation shown)
        // going back from an opened tab the tab param should be removed from url to show initial tab navigation screen
        newUrlSearchParams.delete(tabUrlKey);

        if (newUrlSearchParams.size) {
          // if there are any other search params keep them
          history.push(`?${newUrlSearchParams}`);
        } else {
          // otherwise just update the url to remove all search parameters
          // Remove all search parameters while keeping the current pathname
          history.push(history.location.pathname);
        }

        onChangeTab?.(tabId);
      }
    },
    [activeTabId, history, onChangeTab, queryParams, tabUrlKey]
  );

  useEffect(() => {
    const firstTab = tabs[0]?.id;

    if (!firstTab) return;

    if (isDesktop && !activeTab) changeTab(firstTab);
  }, [isDesktop, activeTab, tabs, changeTab]);

  return (
    <Container>
      <Nav selectedTab={!!activeTab}>
        {tabs.map(tab => (
          <NavItem
            $active={activeTab?.id === tab.id}
            key={tab.id}
            onClick={() => changeTab(tab.id)}
          >
            <Label $active={activeTab?.id === tab.id} isDesktop={isDesktop}>
              {tab.label}
            </Label>
            <GoForwardArrow />
          </NavItem>
        ))}
      </Nav>

      <MobileGoBack
        selectedTab={!activeTab}
        onClick={() => changeTab(undefined)}
        noMargin={noMargin}
      >
        <Heading tag="span" styledAs="h4">
          <GoBackArrow />
          {activeTab?.label}
        </Heading>
      </MobileGoBack>

      {activeTab && <Content key={activeTab.id}>{activeTab.content}</Content>}
    </Container>
  );
};
