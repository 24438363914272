import { ApolloProvider } from '@apollo/client';
import { ApolloProvider as ApolloProviderHooks } from '@apollo/client';
import Honeybadger from '@honeybadger-io/js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'store';

import 'normalize.css';
import { FeatureFlagsProvider } from 'hooks/useFeatureFlags/FeatureFlagsProvider';
import { configure, graphql, utils } from 'kb-shared';
import { theme } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { ErrorCatcher } from 'layouts/errorCatcher/ErrorCatcher';
import { ThemeProvider } from 'styled-components';
import { isOnline } from 'utilities/browser';

import App from './App';
import * as redux from './kb-redux';
import reduxStore from './kb-redux/store';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import './theme/typography.scss';
import 'react-image-crop/dist/ReactCrop.css';
import './cropStyles.scss';

// import './config/newrelic';

const { setClearPwLocalStorage } = utils;

const appLoaded = redux.patient.appLoaded;

// Turn on hot-reloading
// @ts-ignore
// module.hot && module.hot.accept();

// Configure kb-shared
const honeyBadgerConfig = {
  apiKey: process.env.REACT_APP_HONEYBADGER_KEY,
  environment: process.env.REACT_APP_HONEYBADGER_ENV,
  revision: process.env.REACT_APP_HONEYBADGER_REVISION
};

// Configure Honeybadger
// @ts-ignore
Honeybadger.configure(honeyBadgerConfig);

configure({
  bugTracker: Honeybadger
});

// Configure ApolloClient
const client = graphql.client;

// Platform specific config for web
setClearPwLocalStorage(() => store.remove('kindbody-auth-password'));

// Initial load of user into Redux
// @ts-ignore
reduxStore.dispatch(appLoaded());

const handleGoogleScriptError = () => {
  if (!isOnline()) return;

  fetch('https://accounts.google.com/gsi/client')
    .then(() => BugTracker.notify('Google SSO Load Error', 'Google SSO script load error'))
    .catch(error => {
      BugTracker.notify({
        name: 'Google SSO script fetch error',
        message: error.message,
        stack: error.stack,
        context: {
          endpoint: 'https://accounts.google.com/gsi/client',
          details: error?.details,
          userAgent: navigator.userAgent,
          online: navigator.onLine
        },
        tags: ['fetch', 'api'],
        component: 'index.tsx',
        action: 'handleGoogleScriptError'
      });
    });
};

ReactDOM.render(
  <ReduxProvider store={reduxStore}>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
      onScriptLoadError={handleGoogleScriptError}
    >
      <ApolloProvider client={client}>
        <ApolloProviderHooks client={client}>
          <FeatureFlagsProvider>
            <ThemeProvider theme={theme}>
              <ErrorCatcher id="app">
                <App />
              </ErrorCatcher>
            </ThemeProvider>
          </FeatureFlagsProvider>
        </ApolloProviderHooks>
      </ApolloProvider>
    </GoogleOAuthProvider>
  </ReduxProvider>,
  document.getElementById('root')
);

// Turn off Service Worker
unregisterServiceWorker();
//registerServiceWorker();
