import { YellowButton } from 'components/Buttons';
import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const Card = styled.div`
  align-items: center;
  border-radius: 4px;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1em 2em;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    min-width: 500px;
    width: min-content;
  }
`;

export const Name = styled.h2`
  font-size: 1.5em;
  margin: 1em;
  background: white;
`;

export const Description = styled.p`
  margin-bottom: 1em;
  text-align: center;
  background: white;
`;

export const ScheduleButton = styled(YellowButton)`
  margin: 2em;
`;
