import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowRightFromBracket } from 'assets/icons/Icons';
import { Loader } from 'components/Loader/Loader';
import { Button } from 'components/v2/Buttons/Button';
import { Tabs } from 'components/v2/Tabs/Tabs';
import { Heading, Text } from 'components/v2/Typography';
import usePatient from 'hooks/usePatient';
import { ResponsiveContainer } from 'screens/styled_common';
import { pageUrl } from 'utilities/pageUrl';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { useGenerateTabs } from './MyProfile.constants';
import { Header, HeadingButtonWrapper, TextWrap } from './MyProfile.styled';

export function MyProfile() {
  const history = useHistory();
  const { isDesktop } = useBreakpoints();
  const { patient, loading: loadingPatient } = usePatient();
  const tabs = useGenerateTabs(patient);

  const [tabSelected, setTabSelected] = useState(false);

  const onChangeTab = (tabId?: string) => {
    setTabSelected(!!tabId);
  };

  function handleLogoutClick() {
    history.push(pageUrl.logout());
  }

  if (loadingPatient || !patient) return <Loader absoluteCentered />;

  return (
    <ResponsiveContainer paddedContent>
      <Header tabSelected={tabSelected}>
        <HeadingButtonWrapper>
          <Heading>My Profile</Heading>
          <Button
            label="Log out"
            uppercase
            category="primary"
            size={isDesktop ? 'md' : 'sm'}
            gap="md"
            leftIcon={<ArrowRightFromBracket type="solid" />}
            onClick={handleLogoutClick}
          />
        </HeadingButtonWrapper>

        <Text tag="span">
          This is the profile information we currently have on file for you.
          <TextWrap>
            <Text tag="strong">All changes are automatically saved.</Text>
          </TextWrap>
        </Text>
      </Header>

      <Tabs tabs={tabs} tabUrlKey="tab" onChangeTab={onChangeTab} />
    </ResponsiveContainer>
  );
}
