import React from 'react';

import { FilePdf } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Heading, Text } from 'components/v2/Typography';
import { currencyNumberFormat } from 'utilities/number-format';

import Invoice from '../../types/invoice';
import { formatDate } from '../PagableInvoices/PagableInvoices.utils';
import { CardContentItemLabel } from './CardContentItemLabel';
import { CardContentItemValue } from './CardContentItemValue';
import {
  CardContent,
  CardContentRow,
  CardListItem,
  LabelHolder,
  CardContentRowContainer,
  CardContentRowItem,
  CardContentRowButtons,
  CardContentRowTextItems
} from './InvoiceCardListItem.styled';

interface Props {
  invoice: Invoice;
  totalBalance: number;
  isViosPatient: boolean;
  onPayClick: () => void;
  onViewInvoice: () => void;
  onPayWithCredit: () => void;
}

export const InvoiceCardListItem = ({
  invoice,
  totalBalance,
  isViosPatient,
  onPayClick,
  onViewInvoice,
  onPayWithCredit
}: Props) => {
  const { dateOfInvoice, service, balance, id, azEncounterIdentifier, paidViaPpApp } = invoice;

  const invoiceIdentifier = azEncounterIdentifier || id;
  const invoiceBalance = parseFloat(balance || '0');

  const showPayButton = !paidViaPpApp && invoiceBalance > 0;
  const showPayWithCreditButton = showPayButton && totalBalance < 0 && !isViosPatient;
  const showPaidLabel = invoiceBalance === 0;
  const showProcessingLabel = paidViaPpApp && invoiceBalance > 0;
  const showOutstandingLabel = !paidViaPpApp && invoiceBalance > 0;

  const rowItems = [
    { label: 'invoice no.', value: invoiceIdentifier },
    { label: 'date', value: formatDate(dateOfInvoice) },
    { label: 'balance', value: currencyNumberFormat(invoiceBalance) }
  ];

  return (
    <CardListItem>
      <CardContent>
        <CardContentRow>
          <div>
            <CardContentItemLabel value="service" />
            <Heading tag="h3" noMargin>
              {service || '-'}
            </Heading>
          </div>
          <Text tag="span" fontStyle="medium" size="sm">
            {showPaidLabel && <LabelHolder $status="paid">Paid</LabelHolder>}
            {showProcessingLabel && <LabelHolder $status="default">Processing...</LabelHolder>}
            {showOutstandingLabel && <LabelHolder $status="outstanding">Outstanding</LabelHolder>}
          </Text>
        </CardContentRow>

        <CardContentRowContainer>
          <CardContentRowTextItems>
            {rowItems.map((item, index) => {
              return (
                <CardContentRowItem key={index}>
                  <CardContentItemLabel value={item.label} />
                  <CardContentItemValue value={item.value} />
                </CardContentRowItem>
              );
            })}
          </CardContentRowTextItems>

          <CardContentRowButtons>
            <Button
              label="View"
              category="secondary"
              size="md"
              fullWidth
              rightIcon={<FilePdf type="solid" />}
              onClick={onViewInvoice}
              uppercase
            />

            {showPayButton && (
              <Button
                label="Pay now"
                category="primary"
                size="md"
                fullWidth
                onClick={onPayClick}
                uppercase
              />
            )}

            {showPayWithCreditButton && (
              <Button
                label="Pay with credit"
                category="secondary"
                size="md"
                fullWidth
                onClick={onPayWithCredit}
                uppercase
              />
            )}
          </CardContentRowButtons>
        </CardContentRowContainer>
      </CardContent>
    </CardListItem>
  );
};
