import React from 'react';

import { Patient } from 'kb-shared';

import { CareTeam } from './components/CareTeam/CareTeam';
import { ContactInfo } from './components/ContactInfo';
import { Employer } from './components/Employer';
import { Insurance } from './components/Insurance/Insurance';
import { PartnerInvitations } from './components/PartnerInvitations/PartnerInvitations';
import { PatientFields } from './components/PatientFields';
import { PaymentOptions } from './components/PaymentOptions';
import { Trustees } from './components/Trustees';

export const useGenerateTabs = (patient?: Patient) => {
  if (!patient) return [];

  return [
    {
      id: 'demographic',
      label: 'Demographic Information',
      content: <PatientFields {...patient} />
    },
    {
      id: 'contact',
      label: 'Contact Information',
      content: <ContactInfo patient={patient} />
    },
    {
      id: 'payment-options',
      label: 'Payment Options',
      content: <PaymentOptions />
    },
    {
      id: 'partner-invitations',
      label: 'Partner Invitations',
      content: <PartnerInvitations />
    },
    {
      id: 'insurance',
      label: 'Insurance',
      content: <Insurance />
    },
    {
      id: 'employer',
      label: 'Employer Benefit',
      content: <Employer patient={patient} />
    },
    {
      id: 'trustees',
      label: 'Trustees',
      content: <Trustees patient={patient} />
    },
    {
      id: 'care-team',
      label: 'My Care Team',
      content: <CareTeam />
    }
  ];
};
